
import {
  Form,
  Input,
  Button,
  Popover,
  Checkbox,
  DatePicker,
  FormItem,
} from 'element-ui';
import { InfoIcon, EyeIcon, EyeOffIcon } from 'vue-feather-icons';
import CustomerService from '@/services/CustomerService';

export default {
  // auth: false,
  middleware: 'auth',
  props: {
    email: {
      type: String,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    onSuccessCallBack: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showPass: false,
      loading: false,
      customerService: new CustomerService({
        http: this.$http,
        auth: this.$auth,
        store: this.$store,
      }),
      account: {
        email: this.email ? this.email : '',
        pass: '',
        firstname: '',
        lastname: '',
        birthdate: '',
        active: '1',
        newletterActive: false,
        loyaltySubscribe: false,
      },

      rules: {
        email: [
          {
            required: true,
            type: 'email',
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
        firstname: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            min: 3,
            message: this.$t('Length should be more than 3 charachter'),
            trigger: 'blur',
          },
        ],
        lastname: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            min: 3,
            message: this.$t('Length should be more than 3 charachter'),
            trigger: 'blur',
          },
        ],
        pass: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            min: 6,
            max: 15,
            message: this.$t('Length should be 6 to 15'),
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    register() {
      this.loading = true;
      this.customerService
        .register(
          {
            email: this.account.email,
            firstname: this.account.firstname,
            lastname: this.account.lastname,
            password: this.account.pass,
            birthdate: this.account.birthdate,
            active: this.account.active,
            newletterActive: this.account.newletterActive,
            loyaltySubscribe: this.account.loyaltySubscribe,
            languageIsoCode: this.$i18n.locale,
            currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
          },
          this.onSuccessCallBack
        )
        .then((res) => {
          //Perform Success Action
        })
        .catch((error) => {
          // error.response.status Check status code
          this.$message.error(error.response.data.Detail);
        })
        .finally(() => {
          //Perform action in always
          this.loading = false;
        });
    },
    onSubmit() {
      this.$refs['registerInfoForm'].validate((valid) => {
        if (valid) {
          this.register();
        } else {
          this.scrollTo('registerInfoForm');
          return false;
        }
      });
    },

    scrollTo(ref) {
      window.scrollTo({
        top: this.$refs[ref].offsetTop - 146,
        behavior: 'smooth',
      });
    },
  },
  components: {
    Input,
    Button,
    Form,
    InfoIcon,
    Popover,
    Checkbox,
    DatePicker,
    FormItem,
    EyeIcon,
    EyeOffIcon,
  },
};
