
import { ChevronDownIcon } from 'vue-feather-icons';
export default {
  data() {
    return {
      linkWrapperHovred: -1,
    };
  },
  computed: {
    menuData() {
      return this.$store.state.shop.menu.data;
    },

    menuTabs() {
      return this.$store.state.shop.menu.data?.TabBarDatas;
    },
  },
  methods: {
    linkWrapperMouseEnter(index) {
      this.linkWrapperHovred = index;
    },
    linkWrapperMouseLeave() {
      this.linkWrapperHovred = -1;
    },
  },
  components: {
    ChevronDownIcon,
  },
};
