
export default {
  props: {
    idMenuColumn: {
      type: Number,
    },
    items: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {};
  },
  computed: {
    leftItems() {
      try {
        return this.items[0].MenuGroupElements[0];
      } catch (error) {
        return null;
      }
    },
    centerItems() {
      try {
        return this.items[1].MenuGroupElements[0];
      } catch (error) {
        return null;
      }
    },
    rightItems() {
      try {
        if (this.items.length > 2) {
          return this.items[2].MenuGroupElements[0];
        } else {
          return this.items[0].MenuGroupElements[0];
        }
      } catch (error) {
        return null;
      }
    },
  },
  methods: {
    getImage(Media) {
      try {
        return Media.Images.menu_medium;
      } catch (error) {
        return null;
      }
    },
    getLink(item) {
      try {
        const link = item.link;
        if (link.charAt(link.length - 1) === '/') {
          link = link.slice(0, -1);
        }

        // const parts = link.split("/");
        // const permalink = parts[parts.length - 1];
        return link;
      } catch (error) {}

      return '/';
    },
  },
};
