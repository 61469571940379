
import {
  Form,
  Input,
  Button,
  Popover,
  Checkbox,
  DatePicker,
  FormItem,
  Select,
  Option,
  CollapseTransition,
  RadioGroup,
  Radio,
} from 'element-ui';
import {
  InfoIcon,
  EyeIcon,
  EyeOffIcon,
  ChevronDownIcon,
} from 'vue-feather-icons';
import CustomerService from '@/services/CustomerService';
import { AddressHelpers } from '@/helpers/AddressHelpers';

export default {
  // auth: false,
  middleware: 'auth',
  props: {
    email: {
      type: String,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    onSuccessCallBack: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showPass: false,
      loading: false,
      showAddressLine2: false,
      customerService: new CustomerService({
        http: this.$http,
        auth: this.$auth,
        store: this.$store,
      }),
      account: {
        email: this.email || '',
        civility: 1,
        firstname: '',
        lastname: '',
        active: '1',
        addressInfo: {
          Address1: '',
          Address2: '',
          Postcode: '',
          City: '',
          CountryIsoCode: '',
          MobilePhone: '',
          IsInvoice: true,
          IsDelivery: true,
        },
      },

      rules: {
        email: [
          {
            required: true,
            type: 'email',
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
        firstname: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            min: 3,
            message: this.$t('Length should be more than 3 charachter'),
            trigger: 'blur',
          },
        ],
        lastname: [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
          {
            min: 3,
            message: this.$t('Length should be more than 3 charachter'),
            trigger: 'blur',
          },
        ],

        'addressInfo.Address1': [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
        'addressInfo.Postcode': [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
        'addressInfo.City': [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
        'addressInfo.CountryIsoCode': [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
        'addressInfo.MobilePhone': [
          {
            required: true,
            message: this.$t('Required Field'),
            trigger: 'blur',
          },
        ],
      },
    };
  },

  computed: {
    countries() {
      return this.$localisation.countries;
    },
  },
  methods: {
    register() {
      this.loading = true;
      this.customerService
        .registerGuest({
          account: this.account,
          languageIsoCode: this.$i18n.locale,
          currencyIsoCode: this.$localisation.appCurrency.CurrencyIsoCode,
        })
        .then((res) => {
          //Perform Success Action
          this.onSuccessCallBack();
          this.rdirectTo();
        })
        .catch((error) => {
          // error.response.status Check status code
          // this.$message.error(error.response.data.Detail);
        })
        .finally(() => {
          //Perform action in always
          this.loading = false;
        });
    },
    onSubmit() {
      this.$refs['registerInfoForm'].validate((valid) => {
        if (valid) {
          this.register();
        } else {
          this.scrollTo('registerInfoForm');
          return false;
        }
      });
    },
    rdirectTo() {
      let redirectTo = '/cart/delivery';
      this.$router.push({
        path: this.localePath(redirectTo),
      });
    },
    onSelectAddress($event) {
      // const words = $event.formatted_address.split(",");
      // this.addressInfo.Address1 = "";
      // if (words.length) {
      // }
      this.account.addressInfo.Address1 = $event.formatted_address;

      this.getCountrySlected($event.country);

      this.account.addressInfo.Country = $event.country ? $event.country : '';
      this.account.addressInfo.City = $event.city ? $event.city : '';
      this.account.addressInfo.Postcode = $event.postalCode
        ? $event.postalCode
        : '';

      this.$refs['registerInfoForm'].validate();
    },

    getCountrySlected(country) {
      if (country) {
        const country_selected = this.countries.find(
          (item) => item.CountryName?.toLowerCase() === country?.toLowerCase()
        );
        if (country_selected) {
          this.account.addressInfo.CountryIsoCode =
            country_selected.CountryIsoCode;
        } else {
          this.account.addressInfo.CountryIsoCode = '';
        }
      } else {
        this.account.addressInfo.CountryIsoCode = '';
      }
    },

    async getAddresses() {
      const addressHelpers = new AddressHelpers(this.$http, this.$store);
      await addressHelpers.getList();
    },

    onAddressTextChange(e) {
      this.account.addressInfo.Address1 = e.text;
    },

    scrollTo(ref) {
      window.scrollTo({
        top: this.$refs[ref].offsetTop - 146,
        behavior: 'smooth',
      });
    },
  },
  components: {
    Input,
    Button,
    Form,
    InfoIcon,
    ChevronDownIcon,
    Popover,
    Checkbox,
    DatePicker,
    FormItem,
    Select,
    Option,
    EyeIcon,
    EyeOffIcon,
    CollapseTransition,
    RadioGroup,
    Radio,
  },
};
